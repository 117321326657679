import React from 'react';
import styled from '@emotion/styled';
import Hexagon from './hexagon.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper';

import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const Prac = styled.div`
  position: relative;
  flex: 0 0 40%;
  @media (max-width: 1120px) {
    display: block;
    width: 100%;
    margin-top: -40px;
  }
`;
const Flex = styled.div`
  flex: 0 0 33%;
`;

const DisplaySlide = styled.div`
  @media (min-width: 1120px) {
    display: none;
  }
`;
const DisplayGrid = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  flex-wrap: wrap;
  @media (max-width: 1120px) {
    display: none;
  }
`;

const Slide = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  transform: scale(0.7);
  @media (max-width: 768px) {
    transform: scale(0.8);
  }
`;

export default function PracSide({ team, practitioner, onClick }) {
  const handleChange = (name) => {
    onClick(name);
  };
  const highligtBg = (name) => {
    if (name === practitioner) return true;
    else return false;
  };
  return (
    <Prac>
      <DisplaySlide>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          slidesPerView={3}
          spaceBetween={10}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 0.9,
            slideShadows: false,
          }}
          slideShadows={false}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          loop={true}
          initialSlide={-1}
          breakpoints={{
            640: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 5,

              coverflowEffect: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 0.5,
                slideShadows: false,
              },
            },
            1120: {
              enabled: false,
            },
          }}
        >
          {team.map(({ node }, i = 0) => {
            const index = i + 1;
            const value = highligtBg(node.name);
            return (
              <SwiperSlide onClick={() => handleChange(node.name)}>
                <Slide>
                  <Hexagon
                    image={node.image}
                    name={node.name}
                    practice={node.title}
                    key={index}
                    selected={value}
                  />
                </Slide>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </DisplaySlide>
      <DisplayGrid>
        {team.map(({ node }, i = 0) => {
          const index = i + 1;
          const value = highligtBg(node.name);
          return (
            <Flex onClick={() => handleChange(node.name)}>
              <Hexagon
                image={node.image}
                name={node.name}
                practice={node.title}
                key={index}
                selected={value}
              />
            </Flex>
          );
        })}
      </DisplayGrid>
    </Prac>
  );
}

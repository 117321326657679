import React from 'react';
import styled from '@emotion/styled';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Info = styled.div`
  flex: 0 0 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding: 4% 5% 3%;
  @media (max-width: 1120px) {
    height: auto;
    padding: 5%;
  }
`;
const InfoSide = ({ person }) => (
  <Info>
    <h3>{person.node.title}</h3>
    <h2 style={{ color: '#4d8ec7', marginTop: 0 }}>{person.node.name}</h2>
    <div
      dangerouslySetInnerHTML={{
        __html: person.node.shortBio.childMarkdownRemark.html,
      }}
    />
    <ButtonDiv>
      <Jane href="https://stasisrehab.janeapp.com/">
        <Button>Book with {person.node.name}</Button>
      </Jane>
    </ButtonDiv>
  </Info>
);

export default InfoSide;

const Button = styled.button`
  height: 100%;
  width: auto;
  font-size: 22px;
  font-variant: all-small-caps;
  color: black;
  background: none;
  white-space: nowrap;
  padding: 5px 15px 8px;
  border: 1px solid black;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18;
  }
  transition: background 0.2s linear;
  :hover {
    background: #084f87;
    color: white;
  }
`;
const Jane = styled(OutboundLink)`
  width: auto;
  height: 50px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin: 25px 0;
`;
const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
`;

import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import PracSide from '../components/team/prac-side';
import InfoSide from '../components/team/info-side';
import { Seo } from '../components/layout/seo';

const TeamFlex = styled.div`
  max-width: 100%;
  padding: 2vw calc((100vw - 1200px) / 2);
  display: flex;
  @media (max-width: 1120px) {
    flex-direction: column-reverse;
  }
`;
const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #084f87;
  margin: 20px 0 30px;
`;

const Practitioner = (props) => {
  const [practitioner, setPractitioner] = useState('Sukhi Grewal');

  const handleChange = (newValue) => {
    setPractitioner(newValue);
  };

  const team = props.data.allContentfulPerson.edges;

  const selectPerson = (team = [], name) => {
    const result = team.find(({ node }) => {
      return node && node.name === name;
    });
    return result;
  };

  const selectedPractitioner = selectPerson(team, practitioner);

  return (
    <Layout>
      <TeamFlex>
        <InfoSide person={selectedPractitioner} />
        <PracSide
          team={team}
          practitioner={practitioner}
          onClick={handleChange}
        />
      </TeamFlex>
    </Layout>
  );
};

export const Head = ({ location }) => (
  <Seo
    title="Meet the Stasis Team"
    pathname={location.pathname}
    description="Learn more about our practitioners and how they can help you."
  />
);

export default Practitioner;

export const pageQuery = graphql`
  query {
    allContentfulPerson(sort: { fields: index }) {
      edges {
        node {
          index
          name
          title
          image {
            description
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          shortBio {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
